<template>
  <div class="container">
    <pre>@state {{ $store.state }}</pre>
    <pre>pubtoken {{ pubtoken }}</pre>
  </div>
</template>

<script>
export default {
  name: 'Page',
  data() {
    return {
      pubtoken: localStorage.getItem('pubtoken')
    }
  }
}
</script>
